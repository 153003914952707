import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { Header } from "../../components/Header"
import { Layout } from "../../components/layout"
import { Footer } from "../../components/Footer"
import { Section } from "../../components/Section"
import { Container } from "../../components/Container"
import { Content } from "../../components/text/Content"
import { P } from "../../components/text/P"
import { UL } from "../../components/text/UL"
import { FluidBackgroundImage } from "../../components/FluidBackgroundImage"

const CenteredContent = styled(Content)`
  margin-left: auto;
  margin-right: auto;
`

const H1White = styled.h1`
  text-align: center;
  color: white;
`

const H2 = styled.h2`
  color: ${(props) => props.theme.ascent};
`

const Page = (props) => {
  const page = props.data.allDataJson.edges[0].node.pages.products_scada
  return (
    <Layout meta={page.meta} loadUikit={true}>
      <Header />
      <FluidBackgroundImage fluid={page.title_bg.childImageSharp.fluid}>
        <Section>
          <Container>
            <CenteredContent maxWidth={979}>
              <H1White>{page.title}</H1White>
              <P data={page.subtitle} />
              <UL data={page.title_list} />
            </CenteredContent>
          </Container>
        </Section>
      </FluidBackgroundImage>
      <Section>
        <Container>
          <H2>{page.specialist_area.title}</H2>
          <UL data={page.specialist_area.desc} />
        </Container>
        <Container>
          <H2>{page.benefits.title}</H2>
          <UL data={page.benefits.desc} />
        </Container>
      </Section>
      <Footer />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          pages {
            products_scada {
              meta {
                url
                title
                description
                imageRootUrl
                image
              }
              title
              subtitle
              title_list
              title_bg {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              specialist_area {
                title
                desc
              }
              benefits {
                title
                desc
              }
            }
          }
        }
      }
    }
  }
`
